var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.users'),"pgIcon":'bx bx-user',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":10,"hasSearchFilter":true,"hasDateFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"hasSearch":true,"lblSearch":_vm.$t('app.search_by_name_or_email_or_phone'),"fields":{
                'id': 'id',
                'name': 'name',
                'email': 'email',
                'mobile': 'mobile',
                'date': 'date',
                'status': 'status'
            },"hasImage":true,"image":_vm.$t('view.image'),"image_style":'width: 40px;height: 40px;border-radius: 50%',"hasName":true,"name":_vm.$t('view.username'),"hasBalance":true,"hasEmail":true,"email":_vm.$t('view.email'),"hasCountryCode":true,"countryCode":_vm.$t('view.ccode'),"hasMobile":true,"mobile":_vm.$t('view.mobile'),"role_name":'member',"hasCourses":true,"hasDate":true,"hasStatus":true,"hasActions":true,"hasSuspend":true,"hasCallWhatsapp":true,"hasWallet":true,"actionable":true,"sortable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }